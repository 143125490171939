import React from "react";
import requireAuth from "../auth/requireAuth";
import { connect } from "react-redux";
import {
  Button,
  Divider,
  Header,
  Modal,
  ModalContent,
} from "semantic-ui-react";
import { fetchUsers } from "../../actions";

import { PDFObject } from "react-pdfobject";

import MessageNotArtBlock from "../../utils/messages/NotArtMessage";
import MessageIsApprovedBlock from "../../utils/messages/isApprovedMessage";

import agentApi from "../../apis/agentApi";

// Call from env
const apiUrl = process.env.REACT_APP_UPLOAD_URL;

class OrderServiceModalClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderService: [],
    };
  }

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ modalOpen: false });

  componentDidMount() {
    const { startOpen } = this.props;

    if (startOpen) {
      this.handleOpen();
    }
  }

  render() {
    const { file, orderService, observation } = this.props;

    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        trigger={
          <button className="ui left labeled green mini icon button">
            <i className="right eye icon" />
            O.S.
          </button>
        }
      >
        <Modal.Actions>
          <Button
            as="a"
            icon="print"
            color="yellow"
            href={apiUrl + `/${file}`}
            target="_blank"
            rel="noreferrer"
          />
          <Button icon="close" color="grey" onClick={this.handleClose} />
        </Modal.Actions>

        <Modal.Header>
          <div>Ordem de Serviço: {orderService}</div>
        </Modal.Header>
        <Modal.Content>
          <PDFObject url={apiUrl + `/${file}`} />
          <Divider />
          <br />
          <Modal.Description>
            <Header>Observações:</Header>
            <p>{observation}</p>
            {/*<div>{findReport}</div>*/}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            as="a"
            icon="print"
            color="yellow"
            href={apiUrl + `/${file}`}
            target="_blank"
            rel="noreferrer"
          />
          <Button icon="close" color="grey" onClick={this.handleClose} />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: Object.values(state.users),
    currentUserId: state.auth.userId,
    // reports: _.orderBy(Object.values(state.reports), "endDate", "desc"),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchUsers,
  })(OrderServiceModalClass)
);

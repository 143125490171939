import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { connect } from "react-redux";
import ReactTable from "react-table-v6";
// import { Button } from "semantic-ui-react";
import {
  fetchOrderServices,
  fetchUsers,
  fetchCompanies,
  selectedOrderService,
} from "../../actions";

import "react-table-v6/react-table.css";
import { tiracentos } from "../../utils/tools/tiracentos";
import OrderServiceModalClass from "./OrderServiceModalClass";

class OrderServiceTableGrid extends React.Component {
  componentDidMount() {
    if (this.props.orderServices.length === 0) {
      this.props.fetchUsers();
      this.props.fetchOrderServices();
      this.props.fetchCompanies();
    }
  }

  renderGrid() {
    // console.log(this.props.orderservices);
    const onRowClick = (state, rowInfo) => {
      return {
        onClick: (e) => {
          this.props.selectedOrderService(rowInfo.original);
        },
      };
    };

    const ptBRFormatter = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    const today = new Date();

    const { userData, currentUserId, orderServices } = this.props;

    if (!orderServices) {
      return <div className="ui active centered inline loader" />;
    }

    const empUserFind = [];
    userData.map((user) => {
      if (user.id === currentUserId) {
        empUserFind.push(user.companies);
      }
    });

    /*const contractList = orderservices.filter(function (item) {
      return item.company.document === empUserFind[0];
    }); */

    const findCompaniesFromUser = _.map(_.map(empUserFind[0]), "id");

    const findCompaniesFromOrderService = _.map(orderServices);

    const orderServiceData = [];
    for (var i = 0; i < findCompaniesFromUser.length; i++) {
      var empUser = findCompaniesFromUser[i];
      console.log(empUser);

      for (var j = 0; j < findCompaniesFromOrderService.length; j++) {
        if (findCompaniesFromOrderService[j].company.id == empUser) {
          orderServiceData.push(findCompaniesFromOrderService[j]);
        }
      }
    }

    return (
      <div>
        <br />
        <ReactTable
          keyField="id"
          getTdProps={onRowClick}
          filterable
          showPagination={true}
          minRows={0}
          previousText="<"
          nextText=">"
          rowsText=""
          pageText="Página"
          ofText="de"
          noDataText="Nenhuma Informação"
          loadingText="Buscando dados..."
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1
          }
          defaultPageSize={10}
          className="-striped -highlight"
          data={orderServiceData}
          resolveData={(data) => data.map((row) => row)}
          columns={[
            {
              Header: "Empresa",
              accessor: "company.fantasyName",
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              }, 
            },
            {
              Header: "Título O.S",
              accessor: "title",
              filterMethod: (filter, row) => {  
                let pattern = new RegExp(filter.value, 'gi');
                let pattern2 = new RegExp(tiracentos(filter.value), 'gi');                 
                              
                return (row[filter.id].match(pattern) || tiracentos(row[filter.id]).match(pattern2)); 
              }, 
            },
            {
              Header: "Início",
              id: "startDate",
              maxWidth: 90,
              accessor: (d) => {
                return d.startDate;
              },
              Cell: (d) => {
                const custom_date = d.value;
                return <span>{ptBRFormatter.format(new Date(custom_date))}</span>;
              },
              filterMethod: (filter, row) => {
                if (filter.value.match(/\//)) {   

                  let filtroArr = filter.value.split("/");

                  if(filtroArr.length == 3){
                    let pattern = new RegExp(filtroArr[2]  + "-" + filtroArr[1] + "-" + filtroArr[0]);
                    return row[filter.id].match(pattern);
                  }else if(filtroArr.length == 2){
                    if(filtroArr[1] == ""){
                      let pattern = new RegExp("-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 2){
                      let pattern = new RegExp("-" + filtroArr[1] + "-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 4){
                      let pattern = new RegExp(filtroArr[1] + "-" + filtroArr[0]+ "-");
                      return row[filter.id].match(pattern);
                    }
                  }
                }else{
                  if(filter.value.length == 2){
                    var pattern = new RegExp("-" + filter.value);
                  }else{
                    var pattern = new RegExp(filter.value);
                  }
                  
                  return row[filter.id].match(pattern);
                }
              },
            },
            {
              Header: "Término",
              id: "endDate",
              maxWidth: 90,
              accessor: (d) => {
                return d.endDate;
              },
              Cell: (d) => {
                const custom_date = d.value;
                return <span>{ptBRFormatter.format(new Date(custom_date))}</span>;
              },
              filterMethod: (filter, row) => {
                if (filter.value.match(/\//)) {  

                  let filtroArr = filter.value.split("/");

                  if(filtroArr.length == 3){
                    let pattern = new RegExp(filtroArr[2]  + "-" + filtroArr[1] + "-" + filtroArr[0]);
                    return row[filter.id].match(pattern);
                  }else if(filtroArr.length == 2){
                    if(filtroArr[1] == ""){
                      let pattern = new RegExp("-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 2){
                      let pattern = new RegExp("-" + filtroArr[1] + "-" + filtroArr[0]);
                      return row[filter.id].match(pattern);
                    }else if(filtroArr[1].length == 4){
                      let pattern = new RegExp(filtroArr[1] + "-" + filtroArr[0]+ "-");
                      return row[filter.id].match(pattern);
                    }
                  }
                }else{
                  if(filter.value.length == 2){
                    var pattern = new RegExp("-" + filter.value);
                  }else{
                    var pattern = new RegExp(filter.value);
                  }
                  
                  return row[filter.id].match(pattern);
                }
              },
              getProps: (state, rowInfo) => ({
                style: {
                  backgroundColor:
                    today > new Date(rowInfo.original.endDate)
                      ? "#fbd9d3"
                      : null,
                },
              }),
            },
            {
              Header: "Tipo de Serviço",
              id: "services",
              accessor: (d) =>
                d.services.map((service) => {
                  return service.name;
                }),
              Cell: (props) =>
                props.original.services.map((service, index) => {
                  return (
                    <div key={index}>
                      <span className="ui label">{service.name}</span>
                      <br />
                    </div>
                  );
                }),
                filterMethod: (filter, row) => {  
                  let pattern = new RegExp(filter.value, "gi");                  
                  return row[filter.id][0].match(pattern) || tiracentos(row[filter.id][0]).match(pattern);                 
                },
            },
            {
              Header: "Tipo de Equipamento",
              id: "equipments",
              accessor: (d) =>
                d.equipments.map((equipment) => {
                  return equipment.name;
                }),
              Cell: (props) =>
                props.original.equipments.map((equipment, index) => {
                  return (
                    <div key={index}>
                      <span className="ui label">{equipment.name}</span>
                      <br />
                    </div>
                  );
                }),
                filterMethod: (filter, row) => {  
                  let pattern = new RegExp(filter.value, "gi");                  
                  return row[filter.id][0].match(pattern) || tiracentos(row[filter.id][0]).match(pattern);                 
                },
            },
            // {
            //   Header: "Status",
            //   columns: [
            //     {
            //       id: "isApproved",
            //       filterable: false,
            //       maxWidth: 70,
            //       accessor: (d) => {
            //         return d.isApproved ? (
            //           <Button
            //             as="a"
            //             color="teal"
            //             size="mini"
            //             icon="thumbs up"
            //             target="_blank"
            //             rel="noreferrer"
            //           />
            //         ) : (
            //           <Button
            //             as="a"
            //             color="red"
            //             size="mini"
            //             icon="thumbs down"
            //             // href={apiUrl + `/${e.file}`}
            //             target="_blank"
            //             rel="noreferrer"
            //           />
            //         );
            //       },
            //     },
            //   ],
            // },
            {
              Header: "Ações",
              columns: [
                {
                  id: "file.id",
                  filterable: false,
                  maxWidth: 170,
                  accessor: (e) => (
                    <OrderServiceModalClass
                      id={e.id}
                      title={e.title}
                      startDate={e.startDate}
                      endDate={e.endDate}
                      observation={e.observation}
                      file={e.file}
                      //isApproved={e.isApproved}
                    />
                  ),
                },
              ],
            },
          ]}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="ui centered grid">
        <div className="ui fifteen wide column">
          <div className="ui row">
            <div className="ui sixteen wide column left bottom aligned content">
              <h1>Ordens de Serviço</h1>
            </div>
          </div>
          <div className="ui fifteen wide column">
            <div className="ui divider" />
          </div>
        </div>

        <div className="ui fifteen wide column">{this.renderGrid()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: Object.values(state.users),
    currentUserId: state.auth.userId,
    orderServices: _.orderBy(Object.values(state.orderServices), "endDate", "desc"),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchUsers,
    fetchCompanies,
    fetchOrderServices,
    selectedOrderService,
  })(OrderServiceTableGrid)
);

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";

import logo from "../logo_g7spr.svg";

class Header extends React.Component {
  renderLinks() {
    if (this.props.isAuthenticated) {
      return (
        <div className={"right menu"}>
          {/*<Link className={"item"} to={"/dashboard"}>*/}
          {/*  DashBoard*/}
          {/*</Link>*/}
          <Link className={"item"} to={"/report/list"}>
            Laudos
          </Link>
          <Link className={"item"} to={"/order_service/list"}>
            Ordens de Serviço
          </Link>
          <Link className={"item"} to={"/contract/list"}>
            Contratos
          </Link>
          <Dropdown item text="Conta">
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link className={"item"} to={"/account/show"}>
                  Perfil
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link className={"item"} to={"/logout"}>
                  Sair
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return (
        <div className={"right menu"}>
          <Link className={"item"} to={"/"}>
            Home
          </Link>
          {/*<Link className={"item"} to={"/login"}>*/}
          {/*  Login*/}
          {/*</Link>*/}
        </div>
      );
    }
  }

  render() {
    return (
      <div className={"ui secondary pointing menu grid"}>
        <Link className={"item"} to={"/report/list"}>
          <img src={logo} alt={"g7 serviços de proteção radilológico"} />
        </Link>
        {this.renderLinks()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { isAuthenticated: state.auth.isAuthenticated };
}

export default connect(mapStateToProps)(Header);

import _ from "lodash";
import React from "react";
import requireAuth from "../auth/requireAuth";
import { connect } from "react-redux";
import { Button, Divider, Header, Modal } from "semantic-ui-react";
import { fetchUsers, fetchOrderServices } from "../../actions";

import history from "../../history";

import { PDFObject } from "react-pdfobject";

import MessageNotArtBlock from "../../utils/messages/NotArtMessage";
import MessageIsApprovedBlock from "../../utils/messages/isApprovedMessage";

import agentApi from "../../apis/agentApi";

// Call from env
const apiUrl = process.env.REACT_APP_UPLOAD_URL;
// const apiStatus = process.env.REACT_APP_API_ENDPOINT;

class OrderServiceModalClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      orderServices: [],
    };
  }

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ modalOpen: false });

  componentDidMount() {
    const { startOpen } = this.props;

    if (startOpen) {
      this.handleOpen();
    }
  }

  processSignature = async () => {
    let res = await agentApi
      .post("api/order_services/status", {
        status: 2,
        orderServices: [this.props.id],
      })
      .then((res) => {
        this.setState({ modalOpen: false });
        window.location.reload();
      });
    // .catch((err) => {
    //   this.setState({ data: err, isLoading: false });
    // });
  };

  /*handleClick = (data) => {
    // console.log(data);
    return this.processSignature(data.status, data.contracts);
  };*/

  render() {
    const {
      userData,
      currentUserId,
      id,
      file,
      title,
      observation,
      isApproved,
    } = this.props;

    const checkUserIsArt = [];
    userData.map((user) => {
      if (user.id === currentUserId) {
        checkUserIsArt.push(user.isArt);
      }
    });

    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        trigger={
          <button className="ui left labeled teal tiny icon button">
            <i className="right eye icon" />
            Ver & Imprimir
          </button>
        }
      >
        <Modal.Actions>
          {/*{checkUserIsArt[0] && !isApproved && (*/}
          {/*  <Button*/}
          {/*    content="Aprovar"*/}
          {/*    labelPosition="right"*/}
          {/*    icon="checkmark"*/}
          {/*    onClick={this.handleClick}*/}
          {/*    positive*/}
          {/*  />*/}
          {/*)}*/}

          {/*{checkUserIsArt[0] && isApproved && (*/}
          {/*  <MessageIsApprovedBlock className="ui six wide right aligned" />*/}
          {/*)}*/}

          {/*{!checkUserIsArt[0] && (*/}
          {/*  <MessageNotArtBlock className="ui six wide right aligned" />*/}
          {/*)}*/}
          <Button
            as="a"
            icon="print"
            color="yellow"
            href={apiUrl + `/${file}`}
            target="_blank"
            rel="noreferrer"
          />
          <Button icon="close" color="grey" onClick={this.handleClose} />
        </Modal.Actions>
        <Modal.Header>
        Ordem de Serviço: {title}
        </Modal.Header>
        <Modal.Content>
          <PDFObject url={apiUrl + `/${file}`} />
          <Divider />
          <br />
          <Modal.Description>
            <Header>Observações:</Header>
            <p>{observation}</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {/*{checkUserIsArt[0] && !isApproved && (*/}
          {/*  <Button*/}
          {/*    content="Aprovar"*/}
          {/*    labelPosition="right"*/}
          {/*    icon="checkmark"*/}
          {/*    onClick={this.handleClick}*/}
          {/*    positive*/}
          {/*  />*/}
          {/*)}*/}
          <Button
            as="a"
            icon="print"
            color="yellow"
            href={apiUrl + `/${file}`}
            target="_blank"
            rel="noreferrer"
          />
          <Button icon="close" color="grey" onClick={this.handleClose} />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: Object.values(state.users),
    currentUserId: state.auth.userId,
    orderServices: _.orderBy(Object.values(state.orderServices), "endDate", "desc"),
  };
};

export default requireAuth(
  connect(mapStateToProps, {
    fetchUsers,
    fetchOrderServices,
  })(OrderServiceModalClass)
);

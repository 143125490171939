import React from "react";
import { Route, Switch } from "react-router-dom";

import Header from "./Header";
import Home from "./Home";
// import Login from "./Login";
import Logout from "./Logout";
// import DashboardShow from "./dashboard/DashboardShow";

import AccountShow from "./accounts/AccountShow";
import ContractList from "./contratcs/ContractTableGrid";
import ReportList from "./reports/ReportTableGrid";
import OrderServiceList from "./orderservices/OrderServicesTableGrid"; 

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          {/*<Route path="/login" component={Login} />*/}
          <Route path="/logout" component={Logout} />

          {/*<Route path="/dashboard" component={DashboardShow} />*/}

          <Route path="/account/show" component={AccountShow} />

          <Route path="/contract/list" component={ContractList} />

          <Route path="/order_service/list" component={OrderServiceList} />

          <Route path="/report/list" component={ReportList} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
